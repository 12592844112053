a {
  color: inherit;
  /* blue colors for links too */
  text-decoration: inherit;
  /* no underline */
}

.bottomNav {
  width: 100%;
  position: fixed;
  display: none;
  bottom: 0;
}

.resultContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
}

.filters {
  padding: 25px;
}

.filterSelect {
  text-decoration: none;
  list-style-type: none;
}

.searchContainer {
  padding-left: 75px;
  padding-right: 75px;
}

.hitBox {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.docAvatar {
  vertical-align: middle;
  width: 128px;
  height: 128px;
  border-radius: 5%;
  margin: 10px;
}

.docDetailBox {
  margin-left: 25px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.headerStyle {
  font-weight: bold;
  text-align: center;
  margin: 10px 0px 10px 0px;
}

.docNameBox {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
}

.docDeetsBox {
  display: flex;
  flex-direction: column;
}

.searchBox {
  margin-top: 8px;
  margin-right: 10px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 899px) {
  .resultContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  .searchContainer {
    padding-left: 15px;
    padding-right: 15px;
  }

  .filterBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
  }

  .filters {
    padding: 7px;
  }

  .bottomNav {
    width: 100%;
    position: fixed;
    bottom: 0;
    display: block;
  }
}

@media only screen and (max-width: 860px) {
  .filterSelect {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 499px) {
  .searchContainer {
    padding: 0;
  }

  .hitBox {
    display: flex;
    flex-direction: row;
    padding: 5px;
  }

  .docAvatar {
    vertical-align: middle;
    width: 100px;
    height: 100px;
    border-radius: 5%;
    margin: 5px;
  }
}

@media only screen and (max-width: 425px) {
  .searchContainer {
    padding: 0;
    width: 320px;
  }

  .hitBox {
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  .docAvatar {
    vertical-align: middle;
    width: 80px;
    height: 80px;
    border-radius: 5%;
    margin: 20;
  }

  .fname {
    font-size: 0.9rem !important;
  }

  .lname {
    font-size: 1.2rem !important;
  }

  .type {
    font-size: 0.8rem !important;
  }

  .headerStyle {
    font-size: 1.5rem !important;
    text-align: center;
  }
}

@media only screen and (max-width: 375px) {
  .fname {
    font-size: 0.8rem !important;
  }

  .lname {
    font-size: 1.1rem !important;
  }

  .type {
    font-size: 0.7rem !important;
  }

  .headerStyle {
    font-size: 1.3rem !important;
    text-align: center;
  }
}
