.avatarChange {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
}

.avatarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nameChange {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-left: 25px;
    padding-right: 25px;
}

.phoneChange {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-left: 25px;
    padding-right: 25px;
}