.registerContainer {
    margin-top: 25px;
}

.formContainer {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}

.imgContainer {
    display: flex;
    justify-content: center;
    margin: 25px 0px 25px 0px;
}

.usrImg {
    width: 256px;
    height: 256px;
}

@media only screen and (max-width: 768px) {
    .formContainer {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
    }
}

@media only screen and (max-width: 899px) {
    .base {
        padding-bottom: 56px;
    }
}



@media only screen and (max-width: 425px) {
    .usrImg {
        width: 187px;
        height: 187px;
    }
}