.usrImg {
    vertical-align: middle;
    width: 128px;
    height: 128px;
    border-radius: 50%;
    margin: 6px;
}

.profileContainer {
    display: flex;
    flex-direction: row;
}

.mobileContainer {
    display: none;
}

.webContainer {
    display: block;
}

.profileBox {
    margin: 35px 25px 0px 0px;
}

@media only screen and (max-width: 899px) {
    .base {
        padding-bottom: 56px;
    }
}

@media only screen and (max-width: 560px) {
    .profileContainer {
        display: flex;
        flex-direction: column;
    }

    .imgBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 0px 15px 0px;
    }


}

@media only screen and (max-width: 460px) {
    .mobileContainer {
        display: block;
    }

    .webContainer {
        display: none;
    }
}